import React from "react";
import { CircularProgress, Box, BoxProps } from "@mui/material";

export type RoutesBlockFallbackProps = {
  source?: string;
  fullHeight?: boolean;
} & BoxProps;

const RoutesBlockFallback: React.FC<React.PropsWithChildren<RoutesBlockFallbackProps>> = ({
  source,
  fullHeight = false,
  ...boxProps
}) => {
  // console.log('LOADING: ' + source)
  return (
    <Box
      {...boxProps}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        height: fullHeight ? "100%" : undefined,
      }}
    >
      <CircularProgress color="primary" size={60} />
      {/* {source && <Box mt={2}>{source}</Box>} */}
    </Box>
  );
};

export default React.memo(RoutesBlockFallback) as typeof RoutesBlockFallback;
