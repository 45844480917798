import "core-js/stable"; //IE 11
import EnvironmentErrorHandler from "Environment/ErrorHandler";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { QueryParamProvider } from "use-query-params";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Theme from "Theme";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider as DateLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "whatwg-fetch";

import PageTitle from "Components/PageTitle";
// import Routes from "Routes";
import RouterSuspense from "Routes/RouterSuspense";
import BlockFallback from "Routes/BlockFallback";
import LocalizationProvider from "Localization/LocalizationProvider";
import { GtmTriggers } from "types";
const Routes = React.lazy(() => import("Routes"));

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  // GTM Print via browser
  useEffect(() => {
    const handler = () => (window as any).dataLayer.push({ event: GtmTriggers.BrowserPrint });
    window.addEventListener("beforeprint", handler);
    return () => window.removeEventListener("beforeprint", handler);
  }, []);

  return (
    <Theme>
      <DateLocalizationProvider dateAdapter={AdapterDateFns}>
        <LocalizationProvider>
          <EnvironmentErrorHandler>
            <CssBaseline />
            <PageTitle />
            <Router basename="/">
              <QueryParamProvider ReactRouterRoute={Route}>
                <RouterSuspense source="BASE ROUTES" fallback={BlockFallback}>
                  <Routes />
                </RouterSuspense>
              </QueryParamProvider>
            </Router>
          </EnvironmentErrorHandler>
        </LocalizationProvider>
      </DateLocalizationProvider>
    </Theme>
  );
};

const renderRoot = (root: Element) => {
  const container = createRoot(root);
  container.render(<App />);
};

document.addEventListener("DOMContentLoaded", () => {
  const root = document.querySelector("#j-root");
  if (root) {
    renderRoot(root);
  }
});
