import React from "react";
import { CircularProgress, Backdrop, Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export type RoutesOverlayFallbackProps = {
  source?: string;
};

const useStyles = makeStyles()(({ zIndex, palette }) => ({
  backdrop: {
    zIndex: zIndex.drawer + 1,
    color: palette.primary.main,
    backgroundColor: "#fff",
  },
}));

const RoutesOverlayFallback: React.FC<React.PropsWithChildren<RoutesOverlayFallbackProps>> = () =>
  // {
  //   source,
  // }
  {
    const { classes } = useStyles();
    // console.log('LOADING: ' + source)
    return (
      <Backdrop key="loading" open={true} transitionDuration={0} className={classes.backdrop}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" size={60} />
          {/* {source && <Box mt={2}>{source}</Box>} */}
        </Box>
      </Backdrop>
    );
  };

export default React.memo(RoutesOverlayFallback) as typeof RoutesOverlayFallback;
