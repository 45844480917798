const sentry = window.Sentry;

const logToSentry = (error: Error, errorInfo?: Record<string, string | undefined>) => {
  if (!sentry) {
    return;
  }
  sentry.withScope(function (scope) {
    if (errorInfo) {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, String(errorInfo[key]));
      });
    }
    sentry.captureException(error);
  });
};

export default logToSentry;
