import { Button, Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ErrorOutlineTwoTone as ErrorIcon } from "@mui/icons-material";
import { ReactNodeLike } from "prop-types";
import React, { ErrorInfo } from "react";
import logToSentry from "./logToSentry";
import { CSSObject } from "@emotion/react";

type State = {
  hasError: boolean;
  httpResponseCode?: number;
};
type Props = {
  children: ReactNodeLike;
};

type MyError = Error & {
  networkError?: {
    response?: Response;
  };
};

const useStyles = makeStyles()(({ typography, spacing }) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    padding: spacing(3),
    marginTop: spacing(5),
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
    ...(typography.h6 as CSSObject),
  },
  icon: {
    marginRight: spacing(2),
  },
  message: {
    flexGrow: 1,
  },
}));

const ErrorMessage: React.FC<React.PropsWithChildren<{ httpResponseCode?: number }>> = () => {
  const { classes } = useStyles();

  const message =
    "We're sorry but something went wrong. We've been notified about this issue and we'll take a look at it shortly.";
  return (
    <Paper key="error" className={classes.root}>
      <div className={classes.icon}>
        <ErrorIcon />
      </div>
      <div className={classes.message}>
        <div>{message}</div>
        <div className="text-right">
          <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
      </div>
    </Paper>
  );
};

class EnvironmentErrorHandler extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: MyError, info?: ErrorInfo) {
    if (error.networkError?.response) {
      const httpResponseCode = error.networkError?.response?.status;
      this.setState({ httpResponseCode });
    }

    //@ts-ignore
    logToSentry(error, info);
  }

  render() {
    const { hasError, httpResponseCode } = this.state;

    if (hasError) {
      return <ErrorMessage key="error" httpResponseCode={httpResponseCode} />;
    }
    return this.props.children;
  }
}

export default EnvironmentErrorHandler;
