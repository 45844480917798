import React, {
  Suspense,
  useEffect,
  useState,
  ComponentType,
  PropsWithChildren,
  ComponentProps,
} from "react";

import OverlayFallback from "./OverlayFallback";

type RouterSuspenseProps<T extends ComponentType<React.PropsWithChildren<unknown>>> = {
  source?: string;
  pause?: boolean;
  wait?: number;
  fallback?: T;
} & ComponentProps<T>;

// TODO: Revert this pause behavior after dev work
const RouterSuspense = <T extends ComponentType<React.PropsWithChildren<any>>>({
  children,
  pause = false,
  // wait = 2000,
  wait,
  fallback: FallbackComponent = OverlayFallback,
  ...props
}: PropsWithChildren<RouterSuspenseProps<T>>): JSX.Element => {
  const [show, setShow] = useState<boolean>(!(pause || wait));

  useEffect(() => {
    if (!pause && wait && wait > 0) setTimeout(() => setShow(true), wait);
  }, []); // eslint-disable-line

  if (!show) return <FallbackComponent {...props} />;
  return <Suspense fallback={<FallbackComponent {...props} />}>{children}</Suspense>;
};

export default RouterSuspense;
