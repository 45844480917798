import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import React from "react";

import theme from "./themeDef";

const HnmiTheme: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </ThemeProvider>
  );
};

export default HnmiTheme;
