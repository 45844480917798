import React, { useMemo } from "react";
// import "./intlPolyfill";
import { IntlProvider } from "react-intl";
import { localizationMessages } from "./messages";
import { LocaleDictionary, LocaleId } from "./types";
import LocaleContext from "./LocaleContext";
// import { useLocalStorage } from "Hooks";

const mergeMessages = (locale: string, messages: any, defaults: any) => {
  const target: typeof messages = {};
  for (let key in defaults) {
    if (Object.prototype.hasOwnProperty.call(messages, key)) {
      target[key] = messages[key];
    } else {
      if (process.env.NODE_ENV !== "production") {
        console.warn(`locale ${locale} is missing key ${key}`);
      }
      target[key] = defaults[key];
    }
  }
  return target as any as LocaleDictionary;
};

const LocalizationProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  // Decision was made to remove localization for the moment, so only return "en" as locale
  // const context = useLocalStorage<LocaleId>("locale", "en");
  const context: [LocaleId, React.Dispatch<React.SetStateAction<LocaleId>>] = ["en", () => {}];
  const [locale] = context;
  const messages = useMemo(() => {
    const english = localizationMessages.en;
    return locale === "en" ? english : mergeMessages(locale, localizationMessages[locale], english);
  }, [locale]);
  return (
    <LocaleContext.Provider value={context}>
      <IntlProvider textComponent={React.Fragment} locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocalizationProvider;
