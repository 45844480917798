import { grey } from "@mui/material/colors";
import { createTheme, alpha } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    default: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    srOnly: true;
  }
}

declare module "@mui/material" {
  interface Color {
    main: string;
    dark: string;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    default: Palette["primary"];
  }

  interface PaletteOptions {
    default: PaletteOptions["primary"];
  }

  interface TypeText {
    hint: string;
  }
}

export const fontFamily = ["Roboto", "sans-serif"].join(",");

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      dark: "#1f3466",
      main: "#0B66AD",
      light: "#DCEFFD",
    },
    error: {
      main: "#DD0F00",
    },
    default: {
      main: grey[300],
      dark: grey[400],
    },
    text: { hint: "rgba(0, 0, 0, 0.38)" },
  },
  typography: {
    fontFamily,
    h1: {
      fontWeight: "bold",
      color: grey[800],
    },
    h2: {
      fontWeight: "bold",
      color: grey[800],
    },
    h3: {
      fontWeight: "bold",
      color: grey[800],
    },
    h4: {
      fontWeight: "bold",
      color: grey[800],
      fontSize: "2.5rem",
    },
    h5: {
      fontWeight: "bold",
      color: grey[800],
      fontSize: "2rem",
    },
    h6: {
      fontWeight: "bold",
      color: grey[800],
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: "1rem",
    },
  },
});

export default createTheme(theme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: { marginBottom: "0.75em" },
      },
      variants: [
        {
          props: { variant: "srOnly" },
          style: {
            position: "absolute",
            left: -10000,
            top: "auto",
            width: 1,
            height: 1,
            overflow: "hidden",
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        color: "default",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          fontWeight: 600,
          "&.Mui-focusVisible": {
            boxShadow:
              "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12),0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #0000ff",
          },
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "default" },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300]),
          },
        },
        {
          props: { variant: "outlined", color: "default" },
          style: {
            color: theme.palette.text.primary,
            borderColor:
              theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
            "&:hover": {
              borderColor:
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.23)"
                  : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
            },
          },
        },
        {
          props: { color: "default", variant: "text" },
          style: {
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        focusRipple: false,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        focusRipple: false,
      },
      styleOverrides: {
        root: {
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          "&:focus-within": {
            boxShadow: "0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #0000ff",
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        disableAutoFocusItem: true,
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        focusRipple: false,
      },
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            backgroundColor: "unset",
          },
        },
      },
    },

    // OVERRIDES

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[2],
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": {
            backgroundColor: "inherit",
          },
        },
        textPrimary: {
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: "#0B66AD !important",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "transparent !important",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "&:focus-within": {
            borderRadius: "4px",
            boxShadow: "0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #0000ff",
          },
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        button: {
          fontFamily,
          borderRadius: "4px",
          textDecoration: "none",
          "&.Mui-underlineHover": {
            textDecoration: "none",
          },
        },
        root: {
          borderRadius: "4px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&:focus": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0000ff !important",
          },
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "unset",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: 0,
          marginBottom: "1.5em",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          overflowX: "visible",
          "&.Mui-focusVisible": {
            boxShadow: "0px 0px 0px 2px #0000ff inset, 0px 0px 0px 4px #ffffff inset",
            backgroundColor: "unset",
          },
          "&:focus": {
            boxShadow: "0px 0px 0px 2px #0000ff inset, 0px 0px 0px 4px #ffffff inset",
            backgroundColor: "unset",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": {
            boxShadow: "0px 0px 0px 2px #0000ff inset, 0px 0px 0px 4px #ffffff inset",
            backgroundColor: "unset",
          },
          "&:focus": {
            boxShadow: "0px 0px 0px 2px #0000ff inset, 0px 0px 0px 4px #ffffff inset",
            backgroundColor: "unset",
          },
        },
      },
    },
  },
});
