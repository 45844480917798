import React, { useContext } from "react";
import { Helmet } from "react-helmet";

import LocaleContext from "Localization/LocaleContext";
import { useFormatMessage } from "Localization/hooks";

// @ts-ignore
import DEFAULT_PREVIEW_IMAGE from "Navigator/Listing/Images/logoSocialShare.png";

const toUrl = (path: string) => {
  if (path.startsWith("//cdn")) {
    return `${window.location.protocol}${path}`;
  }
  return `${window.location.protocol}//${window.location.hostname}${path}`;
};

export type PageTitleProps = {
  title?: string;
  noSuffix?: boolean;
  imagePath?: string;
};

const PageTitle: React.FC<React.PropsWithChildren<PageTitleProps>> = ({
  title,
  imagePath,
  noSuffix = false,
}) => {
  const [locale] = useContext(LocaleContext);
  const t = useFormatMessage();
  const pageTitle = `${title || t("title.prefix")}${
    title && !noSuffix ? t("title.separator") + t("title.prefix") : ""
  }`;

  return (
    <Helmet>
      <title lang={locale}>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta property="og:url" content={window.location.href} />
      <meta property="twitter:title" content={pageTitle} />
      <meta property="og:image" content={toUrl(imagePath || DEFAULT_PREVIEW_IMAGE)} />
      <meta property="twitter:image" content={toUrl(imagePath || DEFAULT_PREVIEW_IMAGE)} />
    </Helmet>
  );
};

export default PageTitle;
